













import { Component, Prop, Vue } from 'vue-property-decorator';

import { fullAddress } from '@/core/helpers/utils/AddressUtils';

import IconMessage from '@/shared/resources/components/IconMessage.vue';
import Divider from '@/shared/resources/components/Divider.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridSpacer from '@/shared/resources/components/grid/GridSpacer.vue';
import InputField from '@/shared/resources/components/forms/fields/InputField.vue';
import AuthRegisterDataInterface from '@/app/modules/auth/interfaces/AuthRegisterDataInterface';
import { Validations } from 'vuelidate-property-decorators';
import { authValidation } from '@/app/modules/auth/config/authValidation';
import ContractorCoreModel from '@/modules/contractors/models/ContractorCoreModel';

@Component({
  components: {
    InputField,
    GridSpacer,
    GridRow,
    Button,
    Divider,
    IconMessage,
  },
})
export default class AuthRegisterSuccess extends Vue {
}
