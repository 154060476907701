









































import { Vue, Component, Prop } from 'vue-property-decorator';
import InputField from '@/shared/resources/components/forms/fields/InputField.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';
import { Validations } from 'vuelidate-property-decorators';
import { authCheckNipValidation, authValidation } from '@/app/modules/auth/config/authValidation';
import Icon from '@/shared/resources/components/Icon.vue';
import ButtonsRow from '@/shared/resources/components/buttons/ButtonsRow.vue';

@Component({
  components: {
    ButtonsRow,
    Icon,
    Button,
    InputField,
  },
})
export default class AuthRegisterNipCheckForm extends Vue {
  /**
   * Validations
   */
  @Validations()
  private get registerRules() {
    return authCheckNipValidation();
  }

  /**
   * Props
   */
  @Prop() private data!: AuthRegisterNipCheckForm;
  @Prop() private loading!: boolean;

  /**
   * Methods
   */
  private submit() {
    this.$v.$touch();

    if (!this.$v.$invalid) {
      this.$emit('submit');
    }
  }

  /**
   * Handlers
   */
  private onSubmit(e: Event) {
    e.preventDefault();
    this.submit();
  }
}
