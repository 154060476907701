




































































import { Component, Vue } from 'vue-property-decorator';

import Card from '@/shared/resources/components/cards/Card.vue';
import Alert from '@/shared/resources/components/Alert.vue';
import AuthRegisterNipCheckForm from '@/app/modules/auth/components/register/AuthRegisterNipCheckForm.vue';
import ApiV1Private from '@/shared/lib/api/query-builders/ApiV1Private';
import ApiV1Settings from '@/core/api/settings/ApiV1Settings';
import AuthRegisterContractorNotFound from '@/app/modules/auth/components/register/AuthRegisterContractorNotFound.vue';
import AuthRegisterContractorHasActiveAccount from '@/app/modules/auth/components/register/AuthRegisterContractorHasActiveAccount.vue';
import AuthRegisterContractorHasInactiveAccount from '@/app/modules/auth/components/register/AuthRegisterContractorHasInactiveAccount.vue';
import AuthRegisterContractorFound from '@/app/modules/auth/components/register/AuthRegisterContractorFound.vue';
import AuthCheckDataInterface from '@/app/modules/auth/interfaces/AuthCheckDataInterface';
import AuthRegisterDataInterface from '@/app/modules/auth/interfaces/AuthRegisterDataInterface';
import ContractorCoreModel from '@/modules/contractors/models/ContractorCoreModel';
import AuthRegisterSuccess from '@/app/modules/auth/components/register/AuthRegisterSuccess.vue';
import AuthLayoutLogo from '@/app/layouts/auth/AuthLayoutLogo.vue';
import AuthRegisterContractorHasUnverifiedEmail from '@/app/modules/auth/components/register/AuthRegisterContractorHasUnverifiedEmail.vue';
import AuthConfirmationSuccess from '@/app/modules/auth/components/register/AuthConfirmationSuccess.vue';
import UISettings from '@/app/lib/settings/UISettings';

@Component({
  components: {
    AuthConfirmationSuccess,
    AuthRegisterContractorHasUnverifiedEmail,
    AuthLayoutLogo,
    AuthRegisterSuccess,
    AuthRegisterContractorFound,
    AuthRegisterContractorHasInactiveAccount,
    AuthRegisterContractorHasActiveAccount,
    AuthRegisterContractorNotFound,
    AuthRegisterNipCheckForm,
    Alert,
    Card,
  },
})
export default class AuthRegisterForm extends Vue {
  /**
   * Data
   */
  private checkData: AuthCheckDataInterface = {
    nip: '',
  };
  private registerData: AuthRegisterDataInterface = {
    email: '',
    regulations: false,
  };
  private loading: boolean = false;
  private expandLogo: boolean = true;

  private registrationSuccess: boolean = false;
  private confirmationSuccess: boolean = false;

  private contractor: ContractorCoreModel | null = null;

  private contractorFound: boolean | null = null;
  private errorMessage: string = '';
  private error: boolean = false;

  /**
   * Display getters
   */
  private get displayCheckNipForm(): boolean {
    return this.contractorFound === null;
  }

  private get displayContractorNotFoundInfo(): boolean {
    return this.contractorFound === false;
  }

  private get displayContractorFoundInfo(): boolean {
    if (!this.contractor) {
      return false;
    }

    return this.contractorFound === true &&
      !this.contractor.hasUser() &&
      !this.registrationSuccess;
  }

  private get displayContractorHasActiveAccountInfo(): boolean {
    if (!this.contractor) {
      return false;
    }

    return this.contractor.hasVerifiedUserEmail() && this.contractor.hasVerifiedUser();
  }

  private get displayContractorHasUnverifiedUserEmail(): boolean {
    if (!this.contractor) {
      return false;
    }

    return !this.contractor.hasVerifiedUserEmail() &&
      this.contractor.hasVerifiedUser() &&
      !this.confirmationSuccess;
  }

  private get displayContractorHasInactiveAccountInfo(): boolean {
    if (!this.contractor) {
      return false;
    }

    return this.contractor.hasUser() && !this.contractor.hasVerifiedUser();
  }

  private get displayRegistrationSuccessInfo(): boolean {
    return this.registrationSuccess;
  }

  private get displayConfirmationSuccessInfo(): boolean {
    return this.confirmationSuccess;
  }

  private get displayError(): boolean {
    return this.error;
  }

  /**
   * Class names
   */
  private get logoClassNames(): object {
    return {
      expanded: this.contractorFound === null,
    };
  }

  /**
   * Methods
   */
  private async checkNip() {
    this.loading = true;
    this.error = false;

    try {
      const response = await new ApiV1Private()
        .schema(ApiV1Settings.schema)
        .where('nip', this.checkData.nip)
        .get('/v2/contractors-auth/check-by-nip');

      this.contractor = new ContractorCoreModel(response.data);
      this.registerData.email = !this.contractor.hasVerifiedUserEmail() && this.contractor.hasVerifiedUser()
        ? this.contractor.user!.email
        : this.contractor.contactEmail;

      this.expandLogo = false;
      this.contractorFound = true;
    } catch (ex) {
      if (ex.isNotFound()) {
        this.contractorFound = false;
      } else {
        this.error = true;
      }
    }

    this.loading = false;
  }

  private async register() {
    if (!this.contractor) {
      return;
    }

    this.loading = true;
    this.error = false;

    try {
      const data: any = {
        contractorId: this.contractor.id,
        ...this.registerData,
      };

      await new ApiV1Private()
        .schema(ApiV1Settings.schema)
        .data(data)
        .post('/v2/contractors-auth/register');

      this.registrationSuccess = true;
    } catch (ex) {
      this.error = true;
    }

    this.loading = false;
  }

  private async confirmEmail() {
    if (!this.contractor) {
      return;
    }

    this.loading = true;
    this.error = false;

    try {
      const data: any = {
        contractorId: this.contractor.id,
        ...this.registerData,
      };

      await new ApiV1Private()
        .schema(ApiV1Settings.schema)
        .data(data)
        .post('/v2/contractors-auth/send-email-confirmation');

      this.confirmationSuccess = true;
    } catch (ex) {
      this.error = true;
    }

    this.loading = false;
  }

  private reset() {
    this.checkData.nip = '';
    this.contractor = null;
    this.contractorFound = null;
    this.error = false;
    this.expandLogo = true;
  }

  private showRegulations() {
    UISettings.openSideModal('RegulationsText', {  }, 'Regulamin', 'medium');
  }

  /**
   * Handlers
   */
  private onNipCheckFormSubmit() {
    this.checkNip();
  }

  private onRegisterFormSubmit() {
    this.register();
  }

  private onConfirmEmailFormSubmit() {
    this.confirmEmail();
  }

  private onBackButtonClick() {
    this.reset();
  }

  private onRegulationsLinkClick() {
    this.showRegulations();
  }
}
